<template>
    <div>
        <el-button type="primary" :loading="loading" @click="open">导入</el-button>
        <el-button type="success" :loading="loading" :disabled="tableData.length < 1 || selected.length < 1"
                   @click="submit">提交
        </el-button>
        <span v-if="tableData.length> 0">共{{ tableData.length }}个广告单元</span>
    </div>
    <div style="margin-top: 10px">
        <el-input v-model="query" prefix-icon="Search" :disabled="loading" style="width: 200px"
                  @input="queryChange"></el-input>
        <span>选中了{{ selected.length }}个广告位</span>
    </div>
    <el-table :data="dataFilter" ref="units" style="margin-top: 10px" @select="select" @select-all="select">
        <el-table-column type="selection" width="60" :selectable="selectable"></el-table-column>
        <el-table-column label="Name" prop="name" width="200"></el-table-column>
        <el-table-column label="产品" width="400">
            <template #default="scope">
                <template v-for="p in $root.product_list">
                    <template v-if="scope.row.package_name === p.bundle_id && scope.row.platform === p.platform">
                        <el-image lazy :src="p.avatar" style="width: 23px;vertical-align: middle">
                            <template #error>
                                <div class="image-slot">
                                    <el-icon>
                                        <Picture/>
                                    </el-icon>
                                </div>
                            </template>
                        </el-image>
                        <span style="margin-left: 5px">{{ p.name || p.nickname }}({{ p.platform }})</span>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
            <template #default="scope">
                <el-icon v-if="scope.row.uploading" class="is-loading">
                    <Loading/>
                </el-icon>
                <template v-else-if="scope.row.uploading === false">
                    <el-icon v-if="scope.row.success" color="var(--el-color-success)">
                        <CircleCheckFilled/>
                    </el-icon>
                    <el-icon v-else color="var(--el-color-error)">
                        <CircleCloseFilled/>
                    </el-icon>
                </template>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened">
        <el-table :data="tableData" v-loading="loading">
            <el-table-column label="Type" prop="Type" width="60"></el-table-column>
            <el-table-column label="AdCode" prop="AdCode" width="300"></el-table-column>
            <el-table-column label="D.eCPM" prop="D.eCPM" width="80"></el-table-column>
        </el-table>
        <template #footer>
            <el-button type="primary" @click="dialog_opened = false">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";
import axios from "ts-axios-new";
import {getLocalObj, setLocalObj} from "../../libs/storage";

export default {
    name: "GAM",
    data() {
        return {
            loading: false, data: [], selected: [], headers: [], tableData: [], query: '', dialog_opened: false,
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/api/v1/max/unit').then(res => {
                this.data = res.data.data.unitList;
                this.loading = false;
                this.selected = getLocalObj('selectedMaxUnit', []);
                this.$nextTick(_ => {
                    this.dataFilter.forEach(d => {
                        if (this.selected.includes(d.id)) {
                            this.$refs.units.toggleRowSelection(d, true);
                        }
                    });
                });
            });
        },
        open() {
            ElMessageBox.prompt('请输入CSV内容', '导入数据', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'textarea',
                inputPattern: /.+/,
                inputErrorMessage: '请输入合法的CSV内容',
                inputPlaceholder: '请输入CSV内容'
            }).then(({value}) => {
                this.parseCSV(value);
            });
        },
        parseCSV(text) {
            this.loading = true;
            const result = Papa.parse(text, {header: true, dynamicTyping: true, skipEmptyLines: true});
            this.tableData = result.data;
            if (this.tableData.length > 0) {
                this.headers = Object.keys(this.tableData[0]);
            }
            if (result.errors && result.errors.length) {
                ElMessage.error('不是合法的csv格式');
            } else {
                this.dialog_opened = true;
            }
            this.loading = false;
        },
        select(selection) {
            this.selected = [];
            selection.forEach(row => {
                this.selected.push(row.id);
            });
            setLocalObj('selectedMaxUnit', this.selected);
        },
        selectable() {
            return !this.loading;
        },
        queryChange() {
            this.$nextTick(_ => {
                this.dataFilter.forEach(d => {
                    if (this.selected.includes(d.id)) {
                        this.$refs.units.toggleRowSelection(d, true);
                    }
                });
            });
        },
        submit() {
            const requests = [];
            this.data.forEach(d => {
                if (this.selected.includes(d.id)) {
                    d.uploading = true;
                    requests.push(axios.put(`/api/v1/max/unit/${d.id}`, {data: this.tableData}).then(res => {
                        d.uploading = false;
                        d.success = !res.data.data.errorMessage;
                    }));
                } else {
                    d.uploading = undefined;
                }
            });
            this.loading = true;
            axios.all(requests).then(_ => {
                this.queryChange();
                this.loading = false;
            });
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                return d.name.toLowerCase().includes(this.query.toLowerCase());
            })
        }
    }
}
</script>

<style scoped>
</style>